import React, { useContext, useEffect, useRef, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Autocomplete, Button, TextField } from "@mui/material";

import AlertMessageComp from "../Componants/AlertMessageCom/AlertMessageComp";
import { Getorder } from "../API/ReportModel";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { Link } from "react-router-dom";
import img from "../../src/Assets/ticket.png";
import { formatISOToCustom } from "../MIS/Global";
const columns = [
  { label: "Order", minWidth: "20%" },
  { label: "Committee", minWidth: "20%" },
  { label: "First Name", minWidth: "20%" },
  { label: "Last Name", minWidth: "20%" },
  { label: "Email", minWidth: "20%" },
  { label: "Phone", minWidth: "20%" },
  { label: "Card", minWidth: "20%" },
  { label: "Tickets", minWidth: "20%" },
  // { label: "IP", minWidth: "20%" },
  // { label: "Country", minWidth: "20%" },
  { label: "Ticket Number", minWidth: "20%" },
];

function createData(
  orderid,
  fname,
  lname,
  email,
  phno,
  cardno,
  tickets,
  orderno,
  qty,
  payment,
  shownm,
  showtime,
  ip,
  country,
  ticnm,
  vendernm,
  trid,
  search
) {
  return {
    orderid,
    fname,
    lname,
    email,
    phno,
    cardno,
    tickets,
    orderno,
    qty,
    payment,
    shownm,
    showtime,
    ip,
    country,
    ticnm,
    vendernm,
    trid,
    search,
  };
}

export default function ReportTbl(props) {
  const [rows, setRows] = useState([{ id: "1" }]);
  const [srows, setSrows] = useState([{ id: "1" }]);
  const [searched, setSearched] = useState("");

  const [alertdata, setAlertdata] = React.useState({});
  const [alertopen, setAlertopen] = React.useState(false);

  //   const { navigate, eventedit, setEventedit } = useContext(UserContext);

  let tabledata = {};
  const formattedDateTime = (dateTime) => {
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    const date = new Date(dateTime).toLocaleDateString('en-GB', options);
    
    const timeOptions = { hour: '2-digit', minute: '2-digit', hour12: true };
    const time = new Date(dateTime).toLocaleTimeString('en-US', timeOptions);
    
    return `${date} ${time}`;
  };
  const getData = async () => {
    setRows([]);
    setSrows([]);
    // let data = { fdate: monday, tdate: saturday };
    let data = {};
    if (props.showid > 0) {
      data = { showid: props.showid };
    }
    tabledata = await Getorder(data);

    // alert(JSON.stringify(tabledata.data));
    const arr = [];
    tabledata.data.forEach((element) => {
      let search = element.fname + "-" + element.lname;
      arr.push(
        createData(
          element.orderid,
          element.fname,
          element.lname,
          element.email,
          element.phno,
          element.cardno,
          element.tickets,
          element.orderno,
          element.qty,
          element.payment,
          element.shownm,
          element.showtime,
          element.ip,
          element.autocountry,
          element?.ticnm,
          element?.vendernm,
          element?.trid,
          search
        )
      );
    
    });
    const sortedData = arr.sort((a, b) => b.orderid - a.orderid);
    setRows(sortedData);
    setSrows(sortedData);
  };

  useEffect(() => {
    getData();
  }, [props.searchdata]);

  const requestSearch = (searchedVal, srows) => {
    const filteredRows = srows.filter((row) => {
      return row.search.toLowerCase().includes(searchedVal.toLowerCase());
    });
    return filteredRows;
  };

  const search = (searchedVal) => {
    let filteredRows = requestSearch(searchedVal, srows);
    setRows(filteredRows);
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const showdate = (showDate) => {
    let month = new Date(showDate).getMonth() + 1;
    if (month.toString().length <= 1) {
      month = `0${month}`;
    }
    let day = new Date(showDate).getDate();
    if (day.toString().length <= 1) {
      day = `0${day}`;
    }
    let hours = new Date(showDate).getHours();
    // if (day.toString().length <= 1) {
    //   day = `0${day}`;
    // }
    let minutes = new Date(showDate).getMinutes();
    let year = new Date(showDate).getFullYear();
    // year = Number(year) - Number(e.target.value);
    return `${day}/${month}/${year} ${hours}:${minutes}`;
  };

  return (
    <div className="">
      <AlertMessageComp
        type={alertdata.type}
        msg={alertdata.msg}
        vertical={alertdata.vertical}
        horizontal={alertdata.horizontal}
        setOpen={setAlertopen}
        open={alertopen}
      />
      <div style={{ display: "flex", justifyContent: "end", padding: "10px" }}>
        <ReactHTMLTableToExcel
          id="test-table-xls-button"
          className="download-table-xls-button"
          table="table-to-xls"
          filename="Ticket Detail"
          sheet="tablexls"
          buttonText="Export"          
        />
      </div>

      <Paper sx={{ width: "100%" }}>
        <TextField
          placeholder="search"
          fullWidth
          className="searchbar"
          onChange={(searchVal) => search(searchVal.target.value)}
        />

        <TableContainer sx={{ maxHeight: 440 }}>
          <Table
            size="small"
            stickyHeader
            aria-label="sticky table"
            sx={{ fontSize: "12px!important" }}
            id="table-to-xls"
          >
            <TableHead>
              {/* <TableRow
                style={{ cursor: "pointer", display: "none" ,height:"220px"}}
                hover
                colSpan={8}
              >
                <TableCell style={{ display:"flex",justifyContent:"flex-start" }} colSpan={8}
                rowSpan={1}
                >
                   <img src="https://www.ticketwizard.ca/images/logo-large.png" alt="" srcset=""
                   style={{width:"150px",height:"150px"}}
                    />
                </TableCell>
              </TableRow> */}
              <TableRow style={{ cursor: "pointer", display: "" }} hover>
                <TableCell
                  style={{
                    fontSize: "16px",
                    fontWeight: "bold",
                    textAlign: "left",
                    color: "red",
                  }}
                  colSpan={8}
                >
                  {rows.length > 1 ? rows[0]?.shownm : ""} -{" "}
                  {rows.length > 1 ? formattedDateTime(formatISOToCustom(rows[0]?.showtime)) : ""}
                </TableCell>
              </TableRow>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ width: column.minWidth,fontSize: "16px" }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {

                  return (
                    
                    [...Array(row.qty)].map((_, index) => {
                    
                    return(
                      <TableRow
                        style={{ cursor: "pointer" }}
                        hover
                        tabIndex={-1}                                             
                      >
                        <TableCell style={{fontSize: "16px"}} >{index==0?row?.orderno:""}</TableCell>
                        <TableCell style={{fontSize: "16px"}} >{index==0?row?.vendernm:""}</TableCell>
                        <TableCell style={{fontSize: "16px"}}>{index==0?row?.fname:""}</TableCell>
                        <TableCell style={{fontSize: "16px"}}>
                          <div>{index==0?row?.lname:""}</div>
                        </TableCell>
                        <TableCell style={{fontSize: "16px"}}>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            {index==0?row?.email:""}
                          </div>
                        </TableCell>

                        <TableCell>
                          <div
                            style={{ display: "flex", alignItems: "center",fontSize:"16px" }}
                          >
                            {index==0?row.phno:""}
                          </div>
                        </TableCell>
                        <TableCell style={{fontSize: "16px"}}>
                          <div
                            style={{ display: "flex", alignItems: "center",fontSize:"16px" }}
                          >
                            {index==0?row.cardno
                              ? row.cardno.length >= 4
                                ? row.cardno.slice(-4)
                                : "NA"
                              : "":""}
                          </div>
                        </TableCell>

                        <TableCell>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              fontSize:"16px",
                            }}
                          >
                          {index==0?row?.ticnm:""}
                            {/* {row.ticnm} */}
                          </div>
                        </TableCell>
                        <TableCell>                        
                          {index==0?row?.trid:""}
                        </TableCell>
                      </TableRow>
                    )
                    
                    })
                    
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          style={{ display: "none" }}
          // rowsPerPageOptions={[100, 250, 500, 1000]}
          component="div"
          // count={rows.length}
          // rowsPerPage={rowsPerPage}
          // page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}
