import {
  Autocomplete,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  TextareaAutosize,
  TextField, Container,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import DoneIcon from "@mui/icons-material/Done";
import VendornameSuggest from "../../Textsuggestfield/VendornameSuggest";
import EventnameSuggest from "../../Textsuggestfield/EventnameSuggest";
import VenunameSuggest from "../../Textsuggestfield/VenunameSuggest";
import { SaveShow } from "../../API/ShowModel";
import { tostmsg } from "../../MIS/Global";
import { useNavigate } from "react-router-dom";
import AlertMessageComp from "../AlertMessageCom/AlertMessageComp";
import { ShowStore } from "../Context/ShowReducer";
import { UserContext } from "../Context/UserContext";
import { Getorder } from "../../API/ReportModel";

const Show = () => {
  function formatISOToCustom(dateString) {
    // Parse the ISO string to a Date object
    const date = new Date(dateString);

    // Extract the components of the date
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getUTCDate()).padStart(2, '0');
    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
    const seconds = String(date.getUTCSeconds()).padStart(2, '0');
  
    // Combine the components into the desired format
    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  
    return formattedDate;
  }
  const { showedit, setShowedit,formname, setFormname  } = React.useContext(UserContext);

  const [showid, setShowid] = useState(showedit[0]?.showid);
  const [vendrid, setVendrid] = useState(
    showedit[0]?.vendrid || 0
  );
  const [vendrname, setVendrname] = useState(
    showedit[0]?.showdetail[0]?.vendrname || ""
  );
  const [eventname, setEventname] = useState(
    showedit[0]?.showdetail[0]?.eventname || "Single Event"
  );
  const [eventid, setEventid] = useState(
    showedit[0]?.showdetail[0]?.eventid || 0
  );
  const [venuname, setVenuname] = useState(
    showedit[0]?.showdetail[0]?.venuname || ""
  );
  const [venueid, setVenueid] = useState(
    showedit[0]?.venueid || 0
  );
  const [uid, setUid] = useState(showedit[0]?.showdetail[0]?.uid || 0);
  const [show, setShow] = useState(showedit[0]?.showdetail[0]?.showname || "");
  const [descrp, setDescrp] = useState(
    showedit[0]?.descr.replace(/#newline#/g, '\n') || ""
  );
  const [ticketinfo, setTicketinfo] = useState(
    showedit[0]?.showdetail[0]?.ticketinfo || ""
  );
  const [ticketblurb, setTicketblurb] = useState(
    showedit[0]?.showdetail[0]?.ticketblurb || ""
  );
  const [isstatus, setIsstatus] = useState(
    showedit[0]?.showdetail[0]?.isstatus || "Draft"
  );
  const [banner, setBanner] = useState([]);
  const [showtime, setShowtime] = useState(formatISOToCustom(showedit?.[0]?.show_time)||new Date())
  // "2022-11-23 00:00"
  const [salestart, setSalestart] = useState(formatISOToCustom(showedit?.[0]?.sale_start)||new Date())
  const [salesend, setSalesend] = useState(formatISOToCustom(showedit?.[0]?.sale_end)||new Date())
  const [tickid, setTickid] = useState(showedit[0]?.tickid || "");
  const [tickidDisable, seTickidDisable] = useState(false)
  const [alertdata, setAlertdata] = React.useState({});
  const [alertopen, setAlertopen] = React.useState(false);
  const navigate = useNavigate();

  const getOrderData = async () => {
   let res = await Getorder({showid:showid,limit:1});    
   if(res?.code==200){
    if(res?.data?.length > 0){
      seTickidDisable(true);
    }
   }        
  };

  useEffect(() => {
    if(showid > 0){
      getOrderData();
    }
  },[showid])
  

  const handleOnChange = (e, val) => {
    let updateDate;
    if (e === "showtime") {
      let month = new Intl.DateTimeFormat("en-US", {
        month: "2-digit",
      }).format(val);
      let day = new Intl.DateTimeFormat("en-US", {
        day: "2-digit",
      }).format(val);
      let year = new Intl.DateTimeFormat("en-US", {
        year: "numeric",
      }).format(val);
      let hour = new Intl.DateTimeFormat("en-US", {
        hour: "numeric",
        hour12: false,
      }).format(val);
      let minute = new Intl.DateTimeFormat("en-US", {
        minute: "numeric",
        hour12: false,
      }).format(val);
      let xDate = `${year}-${month}-${day} ${hour}:${minute}:00`;
      // setShowtime(xDate);
      return xDate;
    } else if (e === "starttime") {
      let month = new Intl.DateTimeFormat("en-US", {
        month: "2-digit",
      }).format(val);
      let day = new Intl.DateTimeFormat("en-US", {
        day: "2-digit",
      }).format(val);
      let year = new Intl.DateTimeFormat("en-US", {
        year: "numeric",
      }).format(val);
      let hour = new Intl.DateTimeFormat("en-US", {
        hour: "numeric",
        hour12: false,
      }).format(val);
      let minute = new Intl.DateTimeFormat("en-US", {
        minute: "numeric",
        hour12: false,
      }).format(val);
      let xDate = `${year}-${month}-${day} ${hour}:${minute}:00`;
      // setSalestart(xDate);
      return xDate;
    } else if (e === "endtime") {
      let month = new Intl.DateTimeFormat("en-US", {
        month: "2-digit",
      }).format(val);
      let day = new Intl.DateTimeFormat("en-US", {
        day: "2-digit",
      }).format(val);
      let year = new Intl.DateTimeFormat("en-US", {
        year: "numeric",
      }).format(val);
      let hour = new Intl.DateTimeFormat("en-US", {
        hour: "numeric",
        hour12: false,
      }).format(val);
      let minute = new Intl.DateTimeFormat("en-US", {
        minute: "numeric",
        hour12: false,
      }).format(val);

      let xDate = `${year}-${month}-${day} ${hour}:${minute}:00`;
      // setSalesend(xDate);
      return xDate;
    }
  };
  function addOneMonth() {
    let newDate = new Date();
    newDate.setMonth(newDate.getMonth() + 1);
    let month = new Intl.DateTimeFormat("en-US", {
      month: "2-digit",
    }).format(newDate);
    let day = new Intl.DateTimeFormat("en-US", {
      day: "2-digit",
    }).format(newDate);
    let year = new Intl.DateTimeFormat("en-US", {
      year: "numeric",
    }).format(newDate);
    let hour = new Intl.DateTimeFormat("en-US", {
      hour: "numeric",
      hour12: false,
    }).format(newDate);
    let minute = new Intl.DateTimeFormat("en-US", {
      minute: "numeric",
      hour12: false,
    }).format(newDate);
    
    let xDate = `${year}-${month}-${day} ${hour}:${minute}:00`;
    return xDate;
  }
  function getFirstDayOfMonth() {
    let newDate= new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    let month = new Intl.DateTimeFormat("en-US", {
      month: "2-digit",
    }).format(newDate);
    let day = new Intl.DateTimeFormat("en-US", {
      day: "2-digit",
    }).format(newDate);
    let year = new Intl.DateTimeFormat("en-US", {
      year: "numeric",
    }).format(newDate);
    let hour = new Intl.DateTimeFormat("en-US", {
      hour: "numeric",
      hour12: false,
    }).format(newDate);
    let minute = new Intl.DateTimeFormat("en-US", {
      minute: "numeric",
      hour12: false,
    }).format(newDate);
    
    let xDate = `${year}-${month}-${day} ${hour}:${minute}:00`;
    return xDate;
  }
 
  function getLastDayOfMonth() {
    let newDate= new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0);
    let month = new Intl.DateTimeFormat("en-US", {
      month: "2-digit",
    }).format(newDate);
    let day = new Intl.DateTimeFormat("en-US", {
      day: "2-digit",
    }).format(newDate);
    let year = new Intl.DateTimeFormat("en-US", {
      year: "numeric",
    }).format(newDate);
    let hour = new Intl.DateTimeFormat("en-US", {
      hour: "numeric",
      hour12: false,
    }).format(newDate);
    let minute = new Intl.DateTimeFormat("en-US", {
      minute: "numeric",
      hour12: false,
    }).format(newDate);
    
    let xDate = `${year}-${month}-${day} ${hour}:${minute}:00`;
    return xDate;
  }
  
  let data = {
    showid: showid,
    showdetail: [
      {
        showname: show,
        vendrname: vendrname,
        eventname: eventname,
        donation: "-",
        descrp: "",
        ticketinfo: ticketinfo,
        ticketblurb: ticketblurb,
        venuname: venuname,
       },
    ],
    showdatedet: [
      {
        showtime: "-",
        salestart: "-",
        salesend: "-",
      },
    ],
    ticketdet: [
     ],
    feestaxdet: [
      {
        convfee1: "",
        convfeesplit: "",
        convfee2: "",
        additionalfee: "10",
        tax: "",
        tickettax: "",
        convfeetax: "",
      },
    ],
    isstatus: isstatus,
    uid: uid,
    drawimg:"-",
    show_time:showid?showtime:addOneMonth(),
    sale_start:showid?salestart:getFirstDayOfMonth()  ,
    sale_end:showid?salesend:getLastDayOfMonth(),
    vendrid:vendrid,
    venueid:venueid,
    descr:descrp,
    tickid:tickid,
  };


  const Owner = [
    { label: "Recurring", id: "Recurring" },
    { label: "Single Event", id: "Single Event" },
    { label: "Staggered", id: "Staggered" },
  ];

  return (
    <div className="main-content">
      <AlertMessageComp
        type={alertdata.type}
        msg={alertdata.msg}
        vertical={alertdata.vertical}
        horizontal={alertdata.horizontal}
        setOpen={setAlertopen}
        open={alertopen}
      />
      <main className="main-size">
        <Grid container spacing={2} sx={{ marginBottom: 2 }}>
          <Grid item md={12} xs={12} sm={12}>
            <h3 className="supcolor3">{formname||"Add New Draw"}</h3>
            <hr />
          </Grid>
          <Grid item md={6} xs={12} sm={12}>
            <VendornameSuggest
              setVendrid={setVendrid}
              vendrname={vendrname}
              setVendrname={setVendrname}
              setVenuname={setVenuname}
              focusval={true}
            />
          </Grid>
          <Grid item md={4} xs={12} sm={12} sx={{display:"none"}}>
            <EventnameSuggest
              setEventid={setEventid}
              eventname={eventname}
              setEventname={setEventname}
            />
             {/* <Autocomplete
              autoFocus
              disablePortal
              id="combo-box-demo"
              size="small"
              // defaultValue="Recurring"
              value={type}
              options={Owner}
              onChange={(e, newValue) => setType(newValue.label)}
              renderInput={(params) => <TextField {...params} label="Event Type" />}
            /> */}
          </Grid>
          <Grid item md={6} xs={12} sm={12}>
          <TextField
              disabled
              size="small"
              id="outlined-case no-input"
              fullWidth
              value={venuname}
              label="Draw Address"
              variant="outlined"
              inputProps={{ maxLength: 100 }}              
            />
            {/* <VenunameSuggest
              setVenueid={setVenueid}
              venuname={venuname}
              setVenuname={setVenuname}
            /> */}
          </Grid>
          <Grid item md={6} xs={12} sm={12}>
            <TextField
              size="small"
              id="outlined-case no-input"
              fullWidth
              value={show}
              label="Draw Name"
              inputProps={{ maxLength: 100 }}
              onChange={async (e) => {
                setShow(e.target.value);
              }}
            />
          </Grid>

          <Grid item md={6} xs={12} sm={12}>
            <TextField
              type="text"
              size="small"              
              fullWidth
              value={tickid}
              label="Ticket Number"
              inputProps={{ maxLength: 20, inputMode: 'numeric', pattern: '[0-9]*' }}              
              onChange={(e) => {
              const value = e.target.value;              
              // Allow only numeric input
              if (/^\d*$/.test(value)) {
                setTickid(value);
              }
            }}
            disabled={tickidDisable}
            />
          </Grid>


          <Grid item md={12} xs={12} sm={12}>
            <TextareaAutosize
              aria-label="minimum height"
              minRows={3}
              placeholder="Description"
              style={{ width: "100%",padding:"5px"}}
              // inputProps={{padding:"10px"}}
              // value={descrp?descrp.replace(/\n/g, '') : ''}
              onChange={async (e) => {
                setDescrp(e.target.value);
              }}
            >{descrp}</TextareaAutosize>
          </Grid>
          <Grid item md={6} xs={12} sm={12} sx={{display:"none"}}>
            <TextareaAutosize
              minRows={3}
              placeholder="Ticket Information"
              style={{ width: "100%",padding:"5px" }}
              value={ticketinfo}
              onChange={async (e) => {
                setTicketinfo(e.target.value);
              }}
            />
          </Grid>
          <Grid item md={12} xs={12} sm={12} sx={{display:"none"}}>
            <TextareaAutosize
              minRows={3}
              placeholder="Ticket blurb"
              style={{ width: "100%",padding:"5px" }}
              value={ticketblurb}
              onChange={async (e) => {
                setTicketblurb(e.target.value);
              }}
            />
          </Grid>

          <Grid item md={12} xs={12} sm={12}>
            <Button
              variant="contained"
              type="button"
              style={{ float: "right" }}
              onClick={() => {
                (async () => {
                  try {
                    const show = await SaveShow(data);
                    let rettostmsg = tostmsg(show);
                    // alert(JSON.stringify(show));
                    setAlertdata(rettostmsg);
                    setAlertopen(rettostmsg.open);

                    if (show.code == 100) {
                    } 

                    if (show.code == 200) {
                      setTimeout(() => {
                        ShowStore(setShowedit, {
                          type: "cleareventedit",
                          data: {},
                        });
                        navigate("/showlist");
                      });
                    }
                  } catch (e) {}
                })();
              }}
            >
              <DoneIcon /> Submit
            </Button>
            <Button
              variant="contained"
              type="button"
              className="primcolor1_bg"
              style={{
                float: "right",
                backgroundColor: "#f3797e",
                marginRight: "10px",
              }}
              onClick={()=>{window.location.reload()}}
            >
              <RestartAltIcon /> Reset
            </Button>
            <Button
              variant="contained"
              type="button"
              className="primcolor1_bg"
              style={{
                float: "right",
                backgroundColor: "#555555",
                marginRight: "10px",
              }}
              onClick={()=>{navigate("/showlist")}}
            >
             Back To Draw List
            </Button>
          </Grid>
        </Grid>
      </main>
    </div>
  );
};

export default Show;
